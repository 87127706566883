import React from "react";
import Page from "./../../components/Page";
import Section from "./../../components/Section";
import SEO from "./../../components/SEO";

import Title, { SubTitle } from "../../components/releaseNotes/Title";
import Header from "../../components/releaseNotes/Header";
import Feature from "../../components/releaseNotes/Feature";
import VideoView from "../../components/releaseNotes/VideoView";

import uiImprovements from "../../images/Tab-size-detail.png";
import displaySet from "../../images/DisplaySet.png";
import MarkdownRenderer from "../../components/MarkdownRenderer";

const ChangeLog = `
- Fixed rt import and multiple data source export, eliminated lock in disable editing mode. ([PR#3715](https://github.com/OHIF/Viewers/pull/3715))
- Fixed displaySet messages in english ([PR#3711](https://github.com/OHIF/Viewers/pull/3711))
- Provided small fix for exception when saving a segment. ([PR#3709](https://github.com/OHIF/Viewers/pull/3709))
- Added default hanging protocol and enabled stylus in webpack for MONAI-OHIF integration. ([PR#3710](https://github.com/OHIF/Viewers/pull/3710))
- Upgraded Cornerstone3D to fix the voi bug ([PR#3707](https://github.com/OHIF/Viewers/pull/3707))
- Fixed the modality unit per target via upgrade of cs3d ([PR#3706](https://github.com/OHIF/Viewers/pull/3706))
- Added button and command to download segmentations as RTSS using RTSS adapter in segmentation panel. ([PR#3692](https://github.com/OHIF/Viewers/pull/3692))
- Improved performance for metadata retrieval in LARGE studies ([PR#3700](https://github.com/OHIF/Viewers/pull/3700))
- Added a new feature to improve user authentication process. ([PR#3701](https://github.com/OHIF/Viewers/pull/3701))
- Removed unit from measurement display when it does not exist. ([PR#3696](https://github.com/OHIF/Viewers/pull/3696))
- Added a new feature to improve user authentication and security. ([PR#3697](https://github.com/OHIF/Viewers/pull/3697))
- Merged PR #2555: Added functionality to support legacy version v2. ([PR#3695](https://github.com/OHIF/Viewers/pull/3695))
- Added external dependency for longitudinal mode and removed longitudinal mode dependency from create-mode, allowing the addition and execution of npm mode package in OHIF. ([PR#3689](https://github.com/OHIF/Viewers/pull/3689))
- Added timing information to provide insight into launch or study viewing events. ([PR#3681](https://github.com/OHIF/Viewers/pull/3681))
- Updated Button component to improve look and feel, reduce complexity for developers, and introduce disabled attribute. ([PR#3473](https://github.com/OHIF/Viewers/pull/3473))
- Added displayArea to hanging protocols for selecting specific portions of an image in the viewport. ([PR#3691](https://github.com/OHIF/Viewers/pull/3691))
- Suggested incorporating option for error checking and central location for links in documentation system. Added reference to link checker tool. ([PR#3650](https://github.com/OHIF/Viewers/pull/3650))
- Fixed regression bug in disableEditing feature; enabled ability to change segment name and delete segmentation when disableEditing is set to true. ([PR#3687](https://github.com/OHIF/Viewers/pull/3687))
- Converted pubSubServiceInterface class to TypeScript and added class member types, resolving syntax error in jest tests. ([PR#3546](https://github.com/OHIF/Viewers/pull/3546))
- Fixed the issue with stack image sync staying active in the toolbar and not working in the new viewport by changing toolbar buttons to include listeners for re-activation in different situations and removing calls to navigation position for FOR linking. ([PR#3663](https://github.com/OHIF/Viewers/pull/3663))
- Fixed the bug that prevented running the app without shared array buffer, enabled changing segmentation color, and adjusted the brush size step size. ([PR#3688](https://github.com/OHIF/Viewers/pull/3688))
- Resolved the issue by using viewport ids from the pre-one up layout to correctly position viewports when restoring a pre-toggle one up layout. ([PR#3677](https://github.com/OHIF/Viewers/pull/3677))
- Closed: Merged Pull Request ([PR#3686](https://github.com/OHIF/Viewers/pull/3686))
- Added babel dependency, fixed path separator issue, and allowed for referencing latest OHIF version in dependencies.json for external modes and extensions. ([PR#3683](https://github.com/OHIF/Viewers/pull/3683))
- Added support for multi-factor authentication. ([PR#3679](https://github.com/OHIF/Viewers/pull/3679))
- Merged: Fixed bug in login functionality. ([PR#3678](https://github.com/OHIF/Viewers/pull/3678))
- Added a new feature to the software allowing users to export their data in multiple formats. ([PR#3676](https://github.com/OHIF/Viewers/pull/3676))
- Added functionality to the search feature, allowing users to filter results by date. ([PR#3675](https://github.com/OHIF/Viewers/pull/3675))
- Streamlined side panel tabs for easier navigation and more vertical space; introduced new SidePanelWithServices component and removed dependency on services. ([PR#3657](https://github.com/OHIF/Viewers/pull/3657))
- Preserved original hanging protocol in fetching and preserving current active protocol. ([PR#3670](https://github.com/OHIF/Viewers/pull/3670))
- Updated react-select library version to resolve compatibility errors with a new UI dependency. ([PR#3622](https://github.com/OHIF/Viewers/pull/3622))
- Added new segmentation mode with brushes and enhanced segmentation and export capabilities. ([PR#3632](https://github.com/OHIF/Viewers/pull/3632))
- Added configuration options for using 16 bit textures, reducing RAM and GPU usage. ([PR#3662](https://github.com/OHIF/Viewers/pull/3662))
- Removed unnecessary code duplication in the file and improved runtime performance. ([PR#3667](https://github.com/OHIF/Viewers/pull/3667))
- Improved module configuration override, hardening patientName util, default layout header's return click handler, rendering of display name button, and passing appConfig to hooks. ([PR#3580](https://github.com/OHIF/Viewers/pull/3580))
- Added troubleshooting steps to the user account control recipe. ([PR#3655](https://github.com/OHIF/Viewers/pull/3655))
- Fixed retrieval of DICOM JSON metadata by declaring retrieve.series.metadata as async and ensuring a promise is returned. ([PR#3659](https://github.com/OHIF/Viewers/pull/3659))
- Fixed merge issues in viewportIndex, changing it to viewportId. ([PR#3654](https://github.com/OHIF/Viewers/pull/3654))
- Implemented new scaling parameter and updated units and formatting from CS3D for calibrate image functionality. ([PR#3505](https://github.com/OHIF/Viewers/pull/3505))
- Added proper error handling to fix infinite loading issue and ensure correct logging of errors in the console for the "DisplaySet Messages" feature. ([PR#3646](https://github.com/OHIF/Viewers/pull/3646))
- Fixed the filename (two instances) "[continuous-integration.md](http://continuous-integration.md/)" and addressed language issue in snippet. ([PR#3645](https://github.com/OHIF/Viewers/pull/3645))
- Added authentication functionality to improve security. ([PR#3640](https://github.com/OHIF/Viewers/pull/3640))
- Added search functionality to the website. ([PR#3642](https://github.com/OHIF/Viewers/pull/3642))
- Exported the checkbox component at the root of the ui package. ([PR#3629](https://github.com/OHIF/Viewers/pull/3629))
- Added highlighting for hydrated thumbnails representing hydratable series and active thumbnails with no image to indicate their status in OHIF. ([PR#3594](https://github.com/OHIF/Viewers/pull/3594))
- Fixed re-renderings and restored hotkeys after refresh. ([PR#3635](https://github.com/OHIF/Viewers/pull/3635))
- Upgraded prettier, extended printWidth, and added prettier-plugin-tailwind for improved code formatting in the project. ([PR#3627](https://github.com/OHIF/Viewers/pull/3627))
- Added new feature to allow users to change their profile picture. ([PR#3623](https://github.com/OHIF/Viewers/pull/3623))
- Removed reliance on viewport index and replaced it with viewportId, resolving issues with inconsistent indexing and unnecessary re-rendering. ([PR#3591](https://github.com/OHIF/Viewers/pull/3591))
- Implemented feature to automatically open data source configuration modal for partially configured sources. ([PR#3620](https://github.com/OHIF/Viewers/pull/3620))
- Fixed handleUnauthenticated in OpenIdConnectRoutes.tsx to redirect for sign in when using a secured dcm4chee archive, allowing users to use OHIF with their data. ([PR#3617](https://github.com/OHIF/Viewers/pull/3617))
- Fixed exception for missing PatientWeight in metadata for a PT series by logging a warning. ([PR#3621](https://github.com/OHIF/Viewers/pull/3621))
- Added buffer definition under webpack config.plugins to resolve the issue of Buffer being undefined when using the datasetToBlob function from the dcmjs dependency. ([PR#3590](https://github.com/OHIF/Viewers/pull/3590))
- Implemented a generic GUI and introduced generic interfaces for configuring Google DICOM web data source and handling text filtering for input. ([PR#3589](https://github.com/OHIF/Viewers/pull/3589))
- Re-ordered destroy calls to fix memory leak issue and improve performance. ([PR#3611](https://github.com/OHIF/Viewers/pull/3611))
- Added a new handler for unsupported DICOM series, flagging them as unusable for viewing in OHIF. ([PR#3601](https://github.com/OHIF/Viewers/pull/3601))
- Added acknowledgment of funding from the IDC project in the [README.md](http://readme.md/). ([PR#3576](https://github.com/OHIF/Viewers/pull/3576))
- Added a variable to prevent editing in OHIF and implemented locking for SEG and RTSTRUCT tables and measurements. ([PR#3586](https://github.com/OHIF/Viewers/pull/3586))
- Fixed the broken multi-study hanging protocols and added an integration test. ([PR#3579](https://github.com/OHIF/Viewers/pull/3579))
- Added server and study fallbacks for unreachable servers and problematic studies, along with a check for SeriesInstanceUID filter in the URL. ([PR#3592](https://github.com/OHIF/Viewers/pull/3592))
- Added delay and cleaned up code; default cache size and added TG-18 test dataset. ([PR#3597](https://github.com/OHIF/Viewers/pull/3597))
- Updated the user interface layout to be more responsive. ([PR#3593](https://github.com/OHIF/Viewers/pull/3593))
- Added hydration functions for auto-hydrating derived series when opening main series in IDC mode. ([PR#3585](https://github.com/OHIF/Viewers/pull/3585))
- Force curly brackets for the codebase in lint(curly) ([PR#3584](https://github.com/OHIF/Viewers/pull/3584))
- Refactored storePresentation to preserve viewport VOI LUT and allow calling from both set and update actions. ([PR#3560](https://github.com/OHIF/Viewers/pull/3560))
- Removed lots of SEG read code and optimized metadata calls for improved performance. ([PR#3577](https://github.com/OHIF/Viewers/pull/3577))
- Added new source-map-loader to enable easy yarn linking of cornerstone3D and provided documentation and video tutorial on how to yarn link. ([PR#3563](https://github.com/OHIF/Viewers/pull/3563))
- Added a feature to allow users to upload images in the chat. ([PR#3561](https://github.com/OHIF/Viewers/pull/3561))
- Refactored cine player to reduce re-render and fixed split button always listening on the click ([PR#3558](https://github.com/OHIF/Viewers/pull/3558))
- Added WindowLevel component with histogram and colormap functionality for changing voi range and transparency.
- Added ViewportWindowLevel component that calculates and displays histograms for loaded volumes on a WindowLevel component.
- Added ActiveViewportWindowLevel component that listens for changes in active viewport index and loads/updates a ViewportWindowLevel component. ([PR#3522](https://github.com/OHIF/Viewers/pull/3522))
- Fixed a typo in the readme file. ([PR#3553](https://github.com/OHIF/Viewers/pull/3553))
- Added check for undefined user and access token in the function's logic. ([PR#3556](https://github.com/OHIF/Viewers/pull/3556))
- Added interface and hook for configuring and replacing data sources in ExtensionManager, fixing issues #3542 and #3230. ([PR#3543](https://github.com/OHIF/Viewers/pull/3543))
- Fixed resize listener in OHIFCornerstoneViewport to properly fire on DICOM SR load and added ready events for image rendered for testing purposes. Also tweaked scrollbar size to reflect number of images. ([PR#3534](https://github.com/OHIF/Viewers/pull/3534))
- Implemented support for using rendered/queried results directly, by using the renderedState to wait for dicom image load instead of timing it and adding a querying parameter to the worklist to record whether a query is in progress or not. ([PR#3540](https://github.com/OHIF/Viewers/pull/3540))
- Improved Button component style and reduced complexity for developers. ([PR#3473](https://github.com/OHIF/Viewers/pull/3473))
- Fixed syntax error by changing file type to .ts and adding class member types in pubSubServiceInterface.js. ([PR#3546](https://github.com/OHIF/Viewers/pull/3546))
- Updated calibrate image in OHIF to use new scaling parameter and formatting from CS3D. ([PR#3505](https://github.com/OHIF/Viewers/pull/3505))
- Added WindowLevel component with histogram and colormap for voi range and transparency control, and ViewportWindowLevel component for calculating and displaying histograms for volumes. ([PR#3522](https://github.com/OHIF/Viewers/pull/3522))
- Added ability to add and configure data sources dynamically in ExtensionManager and DataSource.initialize. ([PR#3543](https://github.com/OHIF/Viewers/pull/3543))
- Fixed resize listener and added ready events for image rendering in OHIFCornerstoneViewport; updated scrollbar size. ([PR#3534](https://github.com/OHIF/Viewers/pull/3534))
- Implemented direct use of rendered/queried results; used cornerstone renderedState for dicom image load and added querying parameter to worklist. ([PR#3540](https://github.com/OHIF/Viewers/pull/3540))
- Implemented modality unit from cornerstone3d, added 'petSeriesModule', and updated units for statistical measurements. ([PR#3533](https://github.com/OHIF/Viewers/pull/3533))
- Moved effect for firing measurement tracking events to TrackedCornerstoneViewport, ensuring measurement tracking even when side study panel is closed. ([PR#3530](https://github.com/OHIF/Viewers/pull/3530))
- Fixed babel version conflicts in CLI extension template. ([PR#3512](https://github.com/OHIF/Viewers/pull/3512))
- Fixed: Resolved issue with search feature not displaying results correctly. ([PR#3525](https://github.com/OHIF/Viewers/pull/3525))
- Exported the InputNumber component and added a label. ([PR#3501](https://github.com/OHIF/Viewers/pull/3501))
- Fixed null PerFrameFunctionalGroupsSequence items in combineFrameInstance utility. ([PR#3515](https://github.com/OHIF/Viewers/pull/3515))
- Fixed dead link and added missing MD file, resulting in working links in documentation. ([PR#3509](https://github.com/OHIF/Viewers/pull/3509))
- Inverted viewports and enabled command for volume viewport. ([PR#3514](https://github.com/OHIF/Viewers/pull/3514))
- Removed JUMP_TO_MEASUREMENT_VIEWPORT listener from OHIFCornerstoneSRViewport. ([PR#3518](https://github.com/OHIF/Viewers/pull/3518))
- Added missing "[user-account-control.md](http://user-account-control.md/)" file and fixed broken links in "Nginx + Image Archive" documentation. ([PR#3509](https://github.com/OHIF/Viewers/pull/3509))`;

function ReleaseNotes() {
  return (
    <Page>
      <SEO title="OHIF v3.7 | OHIF" />
      <Section className="mx-auto">
        <Title title="OHIF Viewer v3.7" secondary="October 11, 2023" />

        <div className="mt-10">
          <Header />
        </div>
        <div className="flex flex-col space-y-32 mt-12 ">
          <div>
            <div id="overview">
              <SubTitle title={"Overview Video"} />
              <VideoView src="https://player.vimeo.com/video/873749971?badge=0&autopause=0&quality_selector=1&progress_bar=1&player_id=0&app_id=58479" />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <div id="new-features">
            <SubTitle title={"New Features"} />
            <div className="space-y-32">
              <Feature
                position="left"
                title="Segmentation Mode"
                videoSrc="https://player.vimeo.com/video/873554295?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
              >
                <p>
                  We are thrilled to roll out our new segmentation mode, packed
                  with a range of interactive tools like brushes, erasers,
                  shapes, and thresholding tools. These tools empower you to
                  create, modify, and perfect your segmentations effortlessly.
                  Once you are satisfied, you can easily download these
                  segmentations or export them directly to your server in DICOM
                  format. Plus, we have optimized our adapters to read
                  segmentation data 3x faster than before, delivering a smoother
                  and more efficient user experience.
                </p>
                <p>
                  We are rolling out volume viewport support for segmentation,
                  as it effectively covers the majority of use cases.
                  Furthermore, we are diligently working on stack viewport
                  segmentation for release in the near future. Stay tuned for
                  more updates!
                </p>
              </Feature>
              <Feature
                title="Cloud Server Configuration UI"
                position="right"
                videoSrc="https://player.vimeo.com/video/873555898?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
              >
                <p>
                  We are excited to announce the introduction of a new method
                  for setting up cloud data sources in the OHIF Viewer. Our User
                  Interface (UI) currently supports Google Healthcare API, and
                  its flexible design enables you to seamlessly incorporate
                  additional cloud services. This enhances its versatility and
                  ensures a personalized solution that perfectly aligns with
                  your unique requirements.
                </p>
              </Feature>
              <Feature
                title="OHIF and MONAI Label"
                position="left"
                videoSrc="https://player.vimeo.com/video/873558489?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
              >
                <p>
                  We partnered with NVIDIA to migrate the OHIF Viewer v2{" "}
                  <a
                    className="text-primary-light"
                    href="https://github.com/Project-MONAI/MONAILabel"
                  >
                    MONAI Label
                  </a>{" "}
                  plugin to the v3 platform. This exciting collaboration enables
                  you to effortlessly connect your MONAI Label server to OHIF
                  Viewer v3 and leverage a wide range of advanced AI features.
                  Experience the power of
                  <a
                    className="text-primary-light"
                    href="https://github.com/Project-MONAI/model-zoo/tree/dev/models/wholeBody_ct_segmentation"
                  >
                    {" "}
                    whole-body CT segmentation model
                  </a>
                  ,{" "}
                  <a
                    className="text-primary-light"
                    href="https://github.com/project-monai/model-zoo"
                  >
                    MONAI model zoo
                  </a>{" "}
                  , train your AI models with active learning, and much more.
                </p>
              </Feature>
              <Feature
                position="right"
                title="NIFTI Volume Loader in Cornerstone 3D"
                videoSrc="https://player.vimeo.com/video/873560793?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
              >
                <p>
                  Cornerstone 3D now fully supports NIFTI data. This exciting
                  addition allows you to utilize all the familiar tools for
                  annotation, manipulation, and segmentation on NIFTI datasets,
                  just as you would with DICOM files. Our future plans include
                  expanding NIFTI support to the OHIF Viewer itself. This
                  enhancement will significantly increase the accessibility of
                  our platform to a broader community of researchers who work
                  with diverse imaging formats.
                </p>
              </Feature>
              <Feature
                position="left"
                title="UI Improvements"
                imageSrcs={[uiImprovements, displaySet]}
              >
                <p>
                  We now notify users about any issues, such as irregular
                  spacing or missing frames, through warning messages. This
                  implementation greatly enhances the reliability of your
                  imaging work.
                </p>
                <p>
                  Take a look at the revamped design of the side panes, which
                  maximizes space for your content while minimizing the room
                  occupied by the panel header.
                </p>
              </Feature>
            </div>
          </div>
          <div id="all-changes"></div>
        </div>
        <div>
          <SubTitle title={"All Changes"} />

          <div className="text-white">
            <MarkdownRenderer text={ChangeLog} />
          </div>
        </div>
      </Section>
    </Page>
  );
}

export default ReleaseNotes;
