import React from "react";

function Title({ title, secondary }) {
  return (
    <div className="text-center mt-12 mb-8">
      <h1 className="text-[52px]  text-primary-light">{title}</h1>
      <h3 className="text-[18px]">{secondary}</h3>
    </div>
  );
}

function SubTitle({ title }) {
  return (
    <div className="text-center mt-8 mb-8">
      <h1 className="text-[48px] text-primary-light">{title}</h1>
    </div>
  );
}

export default Title;
export { SubTitle };
