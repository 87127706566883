import React from "react";
import list from "../../images/list.svg";
import video from "../../images/video.svg";
import newFeatures from "../../images/new-features.svg";

const style =
  "flex space-x-4 items-center text-[18px] hover:text-primary-light cursor-pointer hover:opacity-70";
function Header() {
  return (
    <div className="flex justify-center">
      <div className="flex space-x-24 feature-mobile">
        <a className={style} href="#overview">
          <img src={video} className="w-8" />
          <p className="text-primary-light">Overview Video</p>
        </a>
        <a className={style} href="#new-features">
          <img src={newFeatures} className="w-8" />
          <p className="text-primary-light">New Features</p>
        </a>
        <a className={style} href="#all-changes">
          <img src={list} className="w-8" />
          <p className="text-primary-light">All changes and fixes</p>
        </a>
      </div>
    </div>
  );
}

export default Header;
