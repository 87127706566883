import React, { useState, useEffect, useRef } from "react";
import VideoView from "./VideoView";

function Feature({ title, videoSrc, imageSrcs, position, children }) {
  const [isHidden, setIsHidden] = useState(false);
  const containerRef = useRef(null);

  const textSection = (
    <div className="flex flex-col space-y-8 w-1/2 feature-mobile">
      <h1 className="text-4xl text-blue-100">{title}</h1>
      {children}
    </div>
  );

  const VideoSection = (
    <div className="w-1/2 feature-mobile">
      {videoSrc && <VideoView src={videoSrc} />}
    </div>
  );

  const ImageSection = (
    <div className="flex-col w-1/2 space-y-8">
      {imageSrcs?.map((src, index) => (
        <img key={index} src={src} alt={`Image ${index}`} />
      ))}
    </div>
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        setIsHidden(width > 700);  // Hide if width is more than 700px
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={containerRef}>
      {!isHidden && (
        <div>
          <div className="flex flex-col space-y-5 mt-5 feature-mobile">
            {textSection}
            {videoSrc ? VideoSection : ImageSection}
          </div>
        </div>
        )
      }
      {isHidden && (
          <div className="flex space-x-5 mt-5 feature-mobile">
            {position === "left" ? (
              <>
                {textSection}
                {videoSrc ? VideoSection : ImageSection}
              </>
            ) : (
              <>
                {videoSrc ? VideoSection : ImageSection}
                {textSection}
              </>
            )}
          </div>
      )}
    </div>
  );
}

export default Feature;
