import React from "react";

const VimeoVideo = ({ src }) => {
  return (
    <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
      <iframe
        src={src}
        allow="autoplay; picture-in-picture; clipboard-write; fullscreen"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%"
        }}
      ></iframe>
    </div>
  );
};

export default VimeoVideo;
